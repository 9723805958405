import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../../redux/actions/authActions";
import BarmansBeerClubLogo from "../../BarmansBeerClubLogo";

function Header() {
  const dispatch = useDispatch();
  const signOutHandler = () => {
    dispatch(signOutUser());
  };
  return (
    <div className='flex justify-between items-center w-full bg-white shadow-lg pr-4'>
      <BarmansBeerClubLogo classNameDiv='w-3/12 md:w-1/12' />
      <button
        className='bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow-lg'
        onClick={() => signOutHandler()}
      >
        Sign Out
      </button>
    </div>
  );
}

export default Header;
