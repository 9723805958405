import React from "react";

function ForgotPasswordScreen() {
  return (
    <div>
      <p>I am forgot password</p>
    </div>
  );
}

export default ForgotPasswordScreen;
