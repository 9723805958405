import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import PackageCard from "../../../components/PackageCard";

function PackageCards({ setSelectedPackage }) {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState(null);

  const subscribeClickHandler = (selectedPackage) => {
    setSelectedPackage(selectedPackage);
  };

  useEffect(() => {
    if (packages === null) {
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          setLoading(true);
          const userToken = user.accessToken;
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/packages`,
              {
                headers: {
                  Authorization: "Bearer " + userToken,
                },
              }
            );
            setPackages(data);
            setLoading(false);
          } catch (error) {
            toast.error("Error retrieving packages");
            setLoading(false);
          }
        }
      });
    }
  });

  return (
    <div className='flex flex-col w-full'>
      {loading ? (
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />
        </div>
      ) : packages !== null ? (
        <div className='flex flex-col md:flex-row w-full justify-around'>
          {packages.map((card) => (
            <PackageCard
              key={card.id}
              card={card}
              buttonLabel='Select'
              onClick={() => subscribeClickHandler(card)}
            />
          ))}
        </div>
      ) : (
        <div>
          <p>No Packages</p>
        </div>
      )}
    </div>
  );
}

export default PackageCards;
