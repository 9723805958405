import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import Button from "../../../components/ui/Button";

function CheckoutForm({ subscription }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL,
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      // Customer will be redirected to return_url
    }
  };

  return (
    <div className='flex flex-col mt-4'>
      <h3 className='text-center text-2xl font-semibold'>Checkout</h3>
      <div className='flex flex-col md:flex-row md:space-x-20 mt-8'>
        <form onSubmit={(event) => handleSubmit(event)}>
          <PaymentElement />
          <Button label='Submit' disabled={!stripe} />
        </form>
        <div className='mt-4 md:mt-0'>
          <p className='text-xl font-medium'>
            Checkout for Barmans Beer Club Subscription
          </p>
          <div className='flex justify-between mt-4'>
            <p>Package:</p>
            <p>{subscription.packageName}</p>
          </div>
          <div className='flex justify-between mt-4'>
            <p>Include Beers:</p>
            <p>{subscription.beersIncluded}</p>
          </div>
          <div className='flex justify-between mt-4'>
            <p>Price</p>
            <p>£{subscription.packagePrice / 100}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
