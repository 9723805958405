import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BarmansBeerClubLogo from "../../components/BarmansBeerClubLogo";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import {
  markNotAuthenticated,
  signInUserToken,
} from "../../redux/actions/authActions";

function PaymentComplete() {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className='flex flex-col w-full justify-center items-center'>
      <BarmansBeerClubLogo classNameDiv='w-2/12' />
      <p>Thank you for subscribing!</p>
      <p className='mb-4'>You will shortly be redirected to your dashboard</p>
      <LoadingSpinner />
    </div>
  );
}

export default PaymentComplete;
