import React from "react";
import Button from "./ui/Button";

function PackageCard({
  card,
  onClick,
  buttonLabel,
  hideButton,
  disableButton,
}) {
  return (
    <div
      key={card.id}
      className='flex flex-col bg-slate-200 p-4 space-y-4 w-full md:w-3/12 mb-8 md:mb-0 rounded-lg shadow-lg'
    >
      <h3 className='text-2xl text-center'>{card.packageName}</h3>
      <p>{`Beers Included: ${card.beersIncluded}`}</p>
      <p>
        Delivery{" "}
        {card.packageFrequency === "1Month"
          ? "once a month"
          : card.packageFrequency === "2Weeks"
          ? "every 2 weeks"
          : ""}
      </p>
      <p>{`Price: £${card.packagePrice / 100} per delivery`}</p>
      {!hideButton && (
        <Button
          label={buttonLabel}
          onClick={onClick}
          disabled={disableButton}
        />
      )}
    </div>
  );
}

export default PackageCard;
