import React from "react";
import Header from "./components/Header";

function Layout({ children }) {
  return (
    <div className='flex flex-col w-full min-h-screen'>
      <Header />
      <div className='flex flex-col grow bg-slate-100'>{children}</div>
    </div>
  );
}

export default Layout;
