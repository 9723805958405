import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import {
  markNotAuthenticated,
  signInUserToken,
} from "./redux/actions/authActions";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import DashboardScreen from "./routes/Dashboard";
import LoginScreen from "./routes/Login";
import RegisterScreen from "./routes/Register";
import SubscribeScreen from "./routes/Subscribe";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordScreen from "./routes/ForgotPassword";
import PaymentComplete from "./routes/Subscribe/PaymentComplete";

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.signedIn === null) {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          console.log("Token found");
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
    }
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      {auth.signedIn === null ? (
        // Loading Spinner Hold whilst use effect for token completes
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />
        </div>
      ) : auth.signedIn ? (
        <>
          {auth.user.subscriptionId ? (
            // Routes for authenticated user with subscription
            <Routes>
              <Route path='/' element={<DashboardScreen />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          ) : (
            // Routes for authenticated user without subscription
            <Routes>
              <Route path='/' element={<SubscribeScreen />} />
              <Route path='/complete' element={<PaymentComplete />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          )}
        </>
      ) : (
        // Routes for unauthenticated users
        <Routes>
          <Route path='/' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
      <ToastContainer autoClose={2000} position='bottom-left' />
    </BrowserRouter>
  );
}

export default App;
