import React, { useState } from "react";
import BarmansBeerClubLogo from "../../components/BarmansBeerClubLogo";
import TextField from "../../components/ui/TextField";
import Button from "../../components/ui/Button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createNewUser } from "../../redux/actions/authActions";

function RegisterScreen() {
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();

  const registerHandler = (e) => {
    e.preventDefault();
    if (newUser.confirmPassword !== newUser.password) {
      toast.error("Passwords do not match");
      return;
    }
    dispatch(createNewUser(newUser));
  };
  return (
    <div className='flex flex-col min-h-screen justify-center items-center p-2'>
      <BarmansBeerClubLogo classNameDiv='flex w-8/12 md:w-6/12 lg:w-4/12 xl:w-1/12' />
      <form className='flex flex-col w-full md:w-4/12'>
        <TextField
          label='First Name'
          value={newUser.firstName}
          type='text'
          placeholder='Enter first name'
          autoComplete='given-name'
          onChange={(e) =>
            setNewUser({ ...newUser, firstName: e.target.value })
          }
        />
        <TextField
          label='Last Name'
          value={newUser.lastName}
          type='text'
          placeholder='Enter last name'
          autoComplete='family-name'
          onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
        />
        <TextField
          label='Email Address'
          value={newUser.email}
          type='email'
          placeholder='Enter email address'
          autoComplete='username'
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />
        <TextField
          label='Password'
          value={newUser.password}
          type='password'
          placeholder='Enter new password'
          autoComplete='new-password'
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        />
        <TextField
          label='Confirm Password'
          value={newUser.confirmPassword}
          type='password'
          placeholder='Confirm new password'
          autoComplete='new-password'
          onChange={(e) =>
            setNewUser({ ...newUser, confirmPassword: e.target.value })
          }
        />
        <Button
          label='Join Barmans Beer Club'
          onClick={(e) => registerHandler(e)}
        />
      </form>
    </div>
  );
}

export default RegisterScreen;
