import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout";
import NextDelivery from "./components/NextDelivery";

function DashboardScreen() {
  const user = useSelector((state) => state.auth.user);

  return (
    <Layout>
      <div className='flex flex-col px-8 py-4'>
        <div className='flex flex-col w-full md:w-2/12 mx-auto mb-4 p-4 bg-white rounded-lg shadow-lg'>
          <p className='text-center'>Welcome back {user.firstName}!</p>
        </div>
        <NextDelivery />
      </div>
    </Layout>
  );
}

export default DashboardScreen;
