import * as t from "../constants/authConstants";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseAuth } from "../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import { mapAuthCodeToMessage } from "../../utils/firebaseAuthErrorHandling";

export const createNewUser = (newUser) => async (dispatch) => {
  createUserWithEmailAndPassword(firebaseAuth, newUser.email, newUser.password)
    .then(async (userCredential) => {
      const userToken = userCredential.user.accessToken;
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}/auth`,
          {
            newUser,
          },
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        );
        dispatch({
          type: t.SIGN_IN_USER,
          payload: data,
        });
      } catch (error) {
        console.log(error);
        toast.error("Error Creating User");
      }
    })
    .catch((error) => {
      const message = mapAuthCodeToMessage(error.code);
      console.log(message === "" ? error.code : message);
      toast.error(message === "" ? error.code : message);
    });
};

// Sign in user with email and password to firebase and then retrieve user from database

export const signInUserEmailAndPassword =
  (email, password) => async (dispatch) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        var userToken = userCredential.user.accessToken;
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/auth`,
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          dispatch({
            type: t.SIGN_IN_USER,
            payload: data,
          });
        } catch (error) {
          console.log(error);
          toast.error("Error signing in");
        }
      })
      .catch((error) => {
        const message = mapAuthCodeToMessage(error.code);
        console.log(message === "" ? error.code : message);
        toast.error(message === "" ? error.code : message);
      });
  };

// If firebase authentication already present retrieve database user
export const signInUserToken = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/auth/`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: t.SIGN_IN_USER,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    // clears firebase authentication if error retrieving database user
    dispatch(signOutUser());
  }
};

// Sign out user from firebase and clear user state from redux
export const signOutUser = () => async (dispatch) => {
  signOut(firebaseAuth)
    .then(() => {
      dispatch({
        type: t.SIGN_OUT_USER,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Amends signedIn from null to false. Used following app checking for token on render, null state used to support loading spinner
export const markNotAuthenticated = () => (dispatch) => {
  dispatch({
    type: t.NOT_AUTHENTICATED,
  });
};
