import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useState } from "react";
import BarmansBeerClubLogo from "../../components/BarmansBeerClubLogo";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";
import CheckoutForm from "./components/CheckoutForm";
import PackageCards from "./components/PackageCards";

function SubscribeScreen() {
  const [loading, setLoading] = useState(false);
  const [billingSameShipping, setBillingSameShipping] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [shippingAddress, setShippingAddress] = useState({
    addressName: "Shipping Address",
    addressOne: "",
    addressTwo: "",
    city: "",
    postcode: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    addressName: "Billing Address",
    addressOne: "",
    addressTwo: "",
    city: "",
    postcode: "",
  });
  const [stripePayment, setStripePayment] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: stripePayment?.clientSecret,
  };

  const billingSameShippingHandler = () => {
    setBillingSameShipping(!billingSameShipping);
    if (!billingSameShipping) {
      setBillingAddress({
        ...billingAddress,
        addressOne: shippingAddress.addressOne,
        addressTwo: shippingAddress.addressTwo,
        city: shippingAddress.city,
        postcode: shippingAddress.postcode,
      });
    }
  };

  const subscribeClickHandler = () => {
    setLoading(true);
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userToken = user.accessToken;
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND}/subscriptions`,
            {
              selectedPackage,
              shippingAddress,
              billingAddress,
            },
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          setStripePayment(data);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const disableSubscribe =
    shippingAddress.addressOne === "" ||
    shippingAddress.addressTwo === "" ||
    shippingAddress.city === "" ||
    shippingAddress.postcode === "" ||
    billingAddress.addressOne === "" ||
    billingAddress.addressTwo === "" ||
    billingAddress.city === "" ||
    billingAddress.postcode === "";

  return (
    <div className='flex flex-col w-full px-8 py-4 items-center'>
      <BarmansBeerClubLogo classNameDiv='flex w-10/12 md:w-8/12 lg:w-6/12 xl:w-1/12' />
      {selectedPackage === null && (
        <PackageCards setSelectedPackage={setSelectedPackage} />
      )}
      {selectedPackage !== null && stripePayment === null && (
        <div className='flex flex-col w-full justify-center items-center'>
          <div className='flex flex-col md:flex-row w-full justify-around'>
            <div className='flex flex-col w-full md:w-4/12 px-4'>
              <h3 className='text-2xl'>Shipping Address</h3>
              <TextField
                label='Address Line One'
                value={shippingAddress.addressOne}
                type='text'
                placeholder='Enter first line of address'
                autoComplete='address-line1'
                onChange={(e) =>
                  setShippingAddress({
                    ...shippingAddress,
                    addressOne: e.target.value,
                  })
                }
              />
              <TextField
                label='Address Line Two'
                value={shippingAddress.addressTwo}
                type='text'
                placeholder='Enter second line of address'
                autoComplete='address-line2'
                onChange={(e) =>
                  setShippingAddress({
                    ...shippingAddress,
                    addressTwo: e.target.value,
                  })
                }
              />
              <TextField
                label='Town/City'
                value={shippingAddress.city}
                type='text'
                placeholder='Enter town or city'
                autoComplete='address-level2'
                onChange={(e) =>
                  setShippingAddress({
                    ...shippingAddress,
                    city: e.target.value,
                  })
                }
              />
              <TextField
                label='Postcode'
                value={shippingAddress.postcode}
                type='text'
                placeholder='Enter postcode'
                autoComplete='postal-code'
                onChange={(e) =>
                  setShippingAddress({
                    ...shippingAddress,
                    postcode: e.target.value,
                  })
                }
              />
            </div>
            <div className='flex flex-col w-full md:w-4/12 px-4'>
              <h3 className='text-2xl'>Billing Address</h3>
              <TextField
                label='Address Line One'
                value={billingAddress.addressOne}
                type='text'
                placeholder='Enter first line of address'
                autoComplete='address-line1'
                onChange={(e) =>
                  setBillingAddress({
                    ...billingAddress,
                    addressOne: e.target.value,
                  })
                }
                disabled={billingSameShipping}
              />
              <TextField
                label='Address Line Two'
                value={billingAddress.addressTwo}
                type='text'
                placeholder='Enter second line of address'
                autoComplete='address-line2'
                onChange={(e) =>
                  setBillingAddress({
                    ...billingAddress,
                    addressTwo: e.target.value,
                  })
                }
                disabled={billingSameShipping}
              />
              <TextField
                label='Town/City'
                value={billingAddress.city}
                type='text'
                placeholder='Enter town or city'
                autoComplete='address-level2'
                onChange={(e) =>
                  setBillingAddress({
                    ...billingAddress,
                    city: e.target.value,
                  })
                }
                disabled={billingSameShipping}
              />
              <TextField
                label='Postcode'
                value={billingAddress.postcode}
                type='text'
                placeholder='Enter postcode'
                autoComplete='postal-code'
                onChange={(e) =>
                  setBillingAddress({
                    ...billingAddress,
                    postcode: e.target.value,
                  })
                }
                disabled={billingSameShipping}
              />
            </div>
          </div>
          <div className='flex flex-col w-full md:w-4/12'>
            <div className='flex justify-center items-center space-x-4 mb-4'>
              <input
                type='checkbox'
                id='billingCheckbox'
                checked={billingSameShipping}
                onChange={(e) => billingSameShippingHandler(e.target.value)}
              />
              <label htmlFor='billingCheckbox'>
                Billing address same as shipping
              </label>
            </div>
            <Button
              label='Subscribe'
              onClick={() => subscribeClickHandler()}
              disabled={disableSubscribe || loading}
            />
          </div>
        </div>
      )}
      {stripePayment !== null && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm subscription={selectedPackage} />
        </Elements>
      )}
    </div>
  );
}

export default SubscribeScreen;
