import React from "react";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../utils/dates.util";

function NextDelivery() {
  const user = useSelector((state) => state.auth.user);
  const subscription = user.subscriptionDetails;

  return (
    <div className='flex flex-col w-full md:w-4/12 p-4 bg-white rounded-lg shadow-lg'>
      {subscription.shipmentsDue > 0 && (
        <p className='text-center'>
          Your next delivery will be sent on{" "}
          {formattedDate(subscription.nextShipment)}
        </p>
      )}
      {subscription.shipmentsDue === 0 && (
        <p className='text-center mt-2'>
          Your next payment will be taken on{" "}
          {formattedDate(subscription.nextPayment)}
        </p>
      )}
    </div>
  );
}

export default NextDelivery;
