import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BarmansBeerClubLogo from "../../components/BarmansBeerClubLogo";
import { signInUserEmailAndPassword } from "../../redux/actions/authActions";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const signInHandler = (e) => {
    e.preventDefault();
    dispatch(signInUserEmailAndPassword(email, password));
  };
  return (
    <div className='flex flex-col min-h-screen justify-center items-center'>
      {/* Logo */}
      <BarmansBeerClubLogo classNameDiv='flex w-10/12 md:w-8/12 lg:w-6/12 xl:w-2/12' />
      {/* Sign in form */}
      <form className='flex flex-col w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12'>
        <TextField
          label='Email Address'
          value={email}
          type='email'
          placeholder='Enter email address'
          autoComplete='username'
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label='Password'
          value={password}
          type='password'
          placeholder='Enter password'
          autoComplete='current-password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button label='Sign In' onClick={(e) => signInHandler(e)} />
      </form>
      <p className='mt-4'>
        Not a member yet?{" "}
        <a href='/register' className='text-blue-500 underline'>
          Join Now
        </a>
      </p>
      <p className='mt-4'>
        Forgot Password?{" "}
        <a href='/forgot-password' className='text-blue-500 underline'>
          Click Here
        </a>
      </p>
    </div>
  );
}

export default LoginScreen;
