import React from "react";

function BarmansBeerClubLogo({ classNameDiv }) {
  return (
    <div className={classNameDiv}>
      <img
        src='/assets/images/BarmansBeerClubLogo.png'
        className='w-full'
        alt='Barmans Beer Club Logo'
      />
    </div>
  );
}

export default BarmansBeerClubLogo;
